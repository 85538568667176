<template>
  <h1>{{ $t('login.title') }}</h1>
  <p class="mt-1">{{ $t('login.subtitle') }}
    <router-link to="/register">{{ $t('login.signUp') }}</router-link>
  </p>
  <form class="flex flex-col mt-10" @submit.prevent="submit">
    <input v-model="form.email" :placeholder="$t('login.placeholder.email')" class="p-3 bg-gray rounded-xl mx-auto"
           type="email"/>
    <input v-model="form.password" :placeholder="$t('login.placeholder.password')"
           class="p-3 bg-gray rounded-xl mt-3 mx-auto" type="password"/>
    <button :disabled="!form.email || !form.password || $store.getters['common/waitingForResponse']"
            class="p-3 py-4 bg-blue text-white rounded-xl mt-3 mx-auto button-lg"
            type="submit">
        <span v-if="!$store.getters['common/waitingForResponse']">{{ $t('login.login') }}</span>
        <span v-else><i class="fa fa-spinner fa-spin text-white text-xl"></i></span>
    </button>
    <router-link class="text-disabled underline mx-auto mt-3 text-sm" to="forgot-password">{{
        $t('login.forgotPassword')
      }}?
    </router-link>
    <router-link class="text-disabled underline mx-auto mt-3 text-sm" to="legal-notice">{{
        $t('login.legalNotice')
      }}
    </router-link>
  </form>
</template>

<script>
import axios from '../../axios';

export default {
  data() {
    return {
      form: {
        email: "",
        password: ""
      }
    }
  },
  methods: {
    async submit() {
      let response = await axios.post('/account/login/', {
        email: this.form.email,
        password: this.form.password,
        locale: this.$store.getters['account/selected_locale']
      });
      if (response.status !== 200) {
        return;
      }

      this.$store.commit('messages/remove');
      this.$store.commit('account/login', {
        user: response.data.user
      });
      this.$store.commit('users/set', {
        users: response.data.users
      });
      this.$store.commit('location/set', {
        location: response.data.location
      });
      this.$store.commit('character/set', {
        character: response.data.character
      });
      this.$store.commit('chat/set', {
        chat_rooms: response.data.chat_rooms
      });
      this.$store.commit('activity/set', {
        activities: response.data.activities,
        categories: response.data.categories,
        locale: this.$store.getters['account/selected_locale']
      });

      if (this.$store.getters['character/test_is_over']) {
        await this.$router.push('/home');
      } else if (this.$store.getters['character/test_started']) {
        await this.$router.push('/test');
      } else {
        await this.$router.push('/choose-test');
      }
    }
  }
}
</script>
