<template>
  <h1>{{ $t('forgotPassword.title') }}</h1>
  <p class="mt-1">
    {{ $t('forgotPassword.subtitle') }}
  </p>
  <form @submit.prevent class="flex flex-col mt-10">
    <input v-model="email"
           :placeholder="$t('forgotPassword.placeholder.email')"
           class="p-3 bg-gray rounded-xl mx-auto"
           type="email"/>
    <button @click="send"
            :disabled="$store.getters['common/waitingForResponse']"
            class="p-3 py-4 bg-blue text-white rounded-xl mt-3 mx-auto button-lg"
            type="submit">
      <span v-if="!$store.getters['common/waitingForResponse']">{{ $t('forgotPassword.send') }}</span>
      <span v-else><i class="fa fa-spinner fa-spin text-white text-xl"></i></span>
    </button>
    <p class="mt-3 text-disabled mx-auto text-sm">
      {{ $t('forgotPassword.waitAMinute') }}
      <router-link class="underline text-disabled" to="/login">{{ $t('forgotPassword.signIn') }}</router-link>
    </p>
  </form>
</template>

<script>
import axios from '@/axios';

export default {
  name: 'ForgotPassword',
  data() {
    return {
      email: ''
    }
  },
  methods: {
    async send() {
      const response = await axios.post('/account/request_password_reset/', {
        email: this.email,
        languageCode: this.$store.getters['account/selected_language']
      });

      if(response.status === 200) {
        this.$store.commit('messages/add_message', {
          message: "forgotPassword.emailSent",
          level: "success"
        });
        this.$router.push('/login');
      }
    }
  }
}
</script>
