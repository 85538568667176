<template>
  <h1>{{ $t('resetPassword.title') }}</h1>
  <p class="mt-1">
    {{ $t('resetPassword.subtitle') }}
  </p>
  <form @submit.prevent class="flex flex-col mt-10">
    <input v-model="password1"
           :placeholder="$t('resetPassword.password1')"
           class="p-3 bg-gray rounded-xl mx-auto mb-3"
           minlength="5"
           type="password"/>
    <input v-model="password2"
           :placeholder="$t('resetPassword.password2')"
           class="p-3 bg-gray rounded-xl mx-auto"
           minlength="5"
           type="password"/>
    <button @click="send"
            class="p-3 py-4 bg-blue text-white rounded-xl mt-3 mx-auto button-lg"
            :disabled="$store.getters['common/waitingForResponse']"
            type="submit">
      <span v-if="!$store.getters['common/waitingForResponse']">{{ $t('resetPassword.send') }}</span>
      <span v-else><i class="fa fa-spinner fa-spin text-white text-xl"></i></span>
    </button>
    <p class="mt-3 text-disabled mx-auto text-sm">
      {{ $t('forgotPassword.waitAMinute') }}
      <router-link class="underline text-disabled" to="/login">{{ $t('forgotPassword.signIn') }}</router-link>
    </p>
  </form>
</template>

<script>
import axios from '@/axios';

export default {
  name: 'ResetPassword',
  data() {
    return {
      password1: "",
      password2: "",
      token: null,
      uidb64: null
    }
  },
  methods: {
    async send() {
      if(this.password1.length < 5) {
        this.$store.commit('messages/add_message', {
          message: "resetPassword.passwordTooShort",
          level: "error"
        });
        return;
      }
      if(this.password1 !== this.password2) {
        this.$store.commit('messages/add_message', {
          message: "resetPassword.passwordsDontMatch",
          level: "error"
        });
        return;
      }
      const response = await axios.post('/account/reset_password/', {
        password: this.password1,
        uidb64: this.uidb64,
        token: this.token
      });

      if(response.status === 200) {
        this.$store.commit('messages/add_message', {
          message: "resetPassword.success",
          level: "success"
        });
      }
      await this.$router.push('/login');
    }
  },
  async mounted() {
    let queryString = new URLSearchParams(location.search);
    this.token = queryString.get('token');
    this.uidb64 = queryString.get('uidb');

    if(this.token === null || this.uidb64 === null) {
      this.$store.commit('messages/add_message', {
        message: "resetPassword.invalidLink",
        level: "error"
      });
      await this.$router.push('/login');
    }
  }
}
</script>
