<template>
  <h1>{{ $t('legalNotice.title') }}</h1>
  <p class="mt-1">
    {{ $t('legalNotice.gotLost') }}
    <router-link to="/login">{{ $t('register.signIn') }}</router-link>
  </p>
  <p class="mt-10 overflow-scroll no-scrollbar" style="min-width: 300px; max-height: 300px" v-html="$t('legalNotice.subtitle')">
  </p>
</template>

<script>
export default {
  name: 'LegalNotice'
}
</script>
