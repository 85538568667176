<template>
  <h1>{{ $t('register.title') }}</h1>
  <p class="mt-1">{{ $t('register.subtitle') }}
    <router-link to="/login">{{ $t('register.signIn') }}</router-link>
  </p>
  <form class="flex flex-col mt-10" @submit.prevent="submit">
    <input v-model="form.email"
           :placeholder="$t('register.placeholder.email')"
           @input="setUsername($event)"
           class="p-3 bg-gray rounded-xl mx-auto"
           type="email"/>
    <input v-model="form.username"
           :placeholder="$t('register.placeholder.username')"
           class="p-3 bg-gray rounded-xl mx-auto mt-3"
           type="text"/>
    <input v-model="form.password"
           :placeholder="$t('register.placeholder.password')"
           class="p-3 bg-gray rounded-xl mt-3 mx-auto"
           type="password"/>
    <button class="p-3 py-4 bg-blue text-white rounded-xl mt-3 mx-auto button-lg"
            :disabled="!form.username || !form.email || !form.password || $store.getters['common/waitingForResponse']"
            type="submit">
      <span v-if="!$store.getters['common/waitingForResponse']">{{ $t('register.signUp') }}</span>
      <span v-else><i class="fa fa-spinner fa-spin text-white text-xl"></i></span>
    </button>
    <p class="mt-3 text-disabled text-sm">
      {{ $t('register.privacyPolicyAgree') }}
      <router-link class="text-disabled underline" to="/legal-notice">{{ $t('register.privacyPolicy') }}</router-link>.
    </p>
  </form>
</template>

<script>
import axios from '../../axios';

export default {
  data() {
    return {
      form: {
        email: '',
        username: '',
        password: ''
      }
    }
  },
  methods: {
    setUsername(event) {
      let index = event.target.value.indexOf('@');
      const maxLength = 10;
      if(index === -1) {
        this.form.username = event.target.value.substring(0, maxLength);
        return;
      }
      if(index > maxLength) {
        index = maxLength;
      }
      this.form.username = event.target.value.substring(0, index);
    },
    async submit() {
      if(this.form.password.length < 5) {
        this.$store.commit('messages/add_message', {
          message: 'register.passwordTooShort',
          level: 'error'
        });
        return;
      }
      if(this.form.username.length < 3) {
        this.$store.commit('messages/add_message', {
          message: 'register.usernameTooShort',
          level: 'error'
        });
        return;
      }

      if(this.form.username.length > 10) {
        this.$store.commit('messages/add_message', {
          message: 'register.usernameTooLong',
          level: 'error'
        });
        return;
      }
      const response = await axios.post('/account/register/', {
        email: this.form.email,
        username: this.form.username,
        password: this.form.password,
        languageCode: this.$store.getters['account/selected_locale']
      });

      if(response.status !== 200) {
        return;
      }

      this.$store.commit('messages/add_message', {
        message: 'register.success',
        level: 'success'
      });

      this.form.email = '';
      this.form.username = '';
      this.form.password = '';
    }
  }
}
</script>