<template>
  <div class="h-full relative md:flex md:p-0">
    <div class="h-full cursor-pointer md:w-1/2 bg-gray-animal flex justify-center items-center"
         @click="!modalIsVisible? toggleModal() : null">
      <img src="../assets/animal.gif"/>
      <div class="absolute bg-black h-full bottom-0 w-full md:hidden"
           style="background: linear-gradient(180deg, transparent 0%, transparent 75%, rgb(0 0 0) 100%); opacity: 0.9"></div>
      <div class="block md:hidden absolute bottom-5 text-white text-3xl"
           :class="{'hidden': modalIsVisible}">
        <i class="fas fa-angle-down"></i>
      </div>
    </div>
    <div class="bg-black opacity-10 fixed inset-x-0 inset-y-0 md:hidden"
         :class="{'hidden': !modalIsVisible}"></div>
    <div id="modal"
         class="z-10 absolute inset-0 hidden md:w-1/2 md:flex md:justify-center md:items-center md:relative">
      <div
          class="fixed md:absolute top-0 right-0 bg-lightgray text-black p-2 rounded-xl cursor-pointer mt-2 mr-2 mw-100"
           @click="toggleLanguageChanging">
        {{ $store.getters['account/selected_language'] }}
        <div v-if="language_changing" class="inline"><i class="fas fa-angle-up"></i></div>
        <div v-else class="inline"><i class="fas fa-angle-down"></i></div>
        <div :class="{hidden: !language_changing}">
          <div v-for="language in $store.getters['account/available_languages']" :key="language" @click="() =>
            changeLocale(language)">
            <hr class="my-1"/>
            {{ language }}
          </div>
        </div>
      </div>
      <div style="width: 95%; max-width: 450px"
          class="absolute left-1/2 top-20 -translate-x-1/2 md:relative md:translate-x-0 md:top-auto md:left-auto rounded-xl bg-white opacity-95 md:opacity-100 p-5">
        <div @click="toggleModal" class="absolute top-1 right-3  cursor-pointer text-3xl md:hidden">
          <i class="fas fa-close"></i>
        </div>
        <Login v-if="$route.name === 'login'"></Login>
        <Register v-else-if="$route.name === 'register'"></Register>
        <ForgotPassword v-else-if="$route.name === 'forgot-password'"></ForgotPassword>
        <ResetPassword v-else-if="$route.name === 'reset-password'"></ResetPassword>
        <LegalNotice v-else-if="$route.name === 'legal-notice'"></LegalNotice>
      </div>

    </div>
  </div>
</template>

<style>
body {
  margin: 0;
  padding: 0;
}

.bg-gray-animal {
  background-color: rgb(246 246 246);
}

input {
  border: 1px solid rgb(226 228 230);
  width: 300px;
}

.mw-100 {
  min-width: 100px;
}

.button-sm {
  width: 100px;
}

.button-lg {
  width: 300px;
}
</style>

<script>
import Login from '@/components/LandingPage/Login';
import Register from "@/components/LandingPage/Register";
import ForgotPassword from "@/components/LandingPage/ForgotPassword";
import ResetPassword from "@/components/LandingPage/ResetPassword";
import LegalNotice from "@/components/LandingPage/LegalNotice";

export default {
  data() {
    return {
      language_changing: false,
      modalIsVisible: false
    }
  },
  components: {
    LegalNotice,
    Login,
    Register,
    ForgotPassword,
    ResetPassword
  },
  methods: {
    toggleLanguageChanging() {
      this.language_changing = !this.language_changing;
    },
    changeLocale(language) {
      this.$store.commit('account/set_selected_language', {language});
      this.$i18n.locale = this.$store.getters['account/selected_locale'];
    },
    toggleModal() {
      if(window.innerWidth > 768) {
        return;
      }
      const modal = document.getElementById('modal');
      modal.classList.toggle('hidden');
      this.modalIsVisible = !modal.classList.contains('hidden');
      if(!this.modalIsVisible) {
        window.addEventListener('scroll', this.toggleModal);
      }
      else {
        window.removeEventListener('scroll', this.toggleModal);
      }
    }
  },
  mounted() {
    let queryString = new URLSearchParams(location.search);
    for (const query of queryString.keys()) {
      if(['token', 'uidb'].indexOf(query) !== -1) {
        continue;
      }
      this.$store.commit('messages/add_message', {message: 'backend.' + query, level: 'info'});
    }
    window.addEventListener('scroll', this.toggleModal);
  }
}
</script>
